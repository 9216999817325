import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Container,
    Grid,
    Typography,
    Stack,
    CircularProgress,
    FormHelperText, Box
} from '@mui/material';
import IconButton from "@mui/material/IconButton";
import axios from '../utils/axios'
import {TextFieldInput} from "../components/TextField";
import * as yup from "yup";
import {toast, ToastContainer} from "react-toastify";
import {ErrorMessage, Field, Formik} from "formik";
import DeleteIcon from "@mui/icons-material/Delete";

const validationSchema = yup.object({
    companyName: yup.string().optional().nullable(),
    name4LoanAgreement: yup.string().optional().nullable(),
    logo: yup.string().optional().nullable(),
    address: yup.string().optional().nullable(),
    city: yup.string().optional().nullable(),
    state: yup.string().optional().nullable(),
    zip: yup.number().optional().nullable().typeError('Street address Zip must be a number'),
    phone: yup.string().optional().nullable(),
    email: yup.string().email('Invalid email format').optional().nullable(),
});

const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/\D/g, '') // Remove non-digit characters
    if (phoneNumber.length <= 3) {
        return phoneNumber
    } else if (phoneNumber.length <= 6) {
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`
    } else {
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
    }
}

export default function Profile() {
    const [logo, setLogo] = useState('');
    const [defaultProfile, setDefaultProfile] = useState({})
    const formikARef = useRef()
    const [isHovered, setIsHovered] = useState(false);

    const role = localStorage.getItem('role');
    let user = {}
    let userStr = localStorage.getItem('user');
    try {
        user = JSON.parse(userStr);
    } catch (_) {

    }
    const userId = user.id;
    let addressField, autocomplete

    useEffect(() => {
        getProfile()
        getDefaultProfile()
        initAutocomplete()
    }, []);

    function initAutocomplete() {
        addressField = document.querySelector('#address')
        autocomplete = new window.google.maps.places.Autocomplete(addressField, {
            componentRestrictions: { country: ['us'] },
            fields: ['address_components', 'geometry'],
            types: ['address'],
        })
        autocomplete.addListener('place_changed', fillInAddress)
    }

    function fillInAddress() {
        const place = autocomplete.getPlace()
        let address1 = ''
        let postcode = ''
        let st = ''

        for (const component of place.address_components) {
            const componentType = component.types[0]
            switch (componentType) {
                case 'street_number': {
                    st = component.long_name
                    console.log('street_number', `${component.long_name} ${address1}`)
                    break
                }
                case 'route': {
                    const addressDetails = st + ' ' + component.short_name
                    formikARef.current.setFieldValue('address', addressDetails)
                    break
                }

                case 'postal_code': {
                    postcode = component.long_name
                    formikARef.current.setFieldValue('zip', postcode)
                    break
                }

                case 'postal_code_suffix': {
                    break
                }

                case 'locality':
                    formikARef.current.setFieldValue('city', component.long_name)

                    break

                case 'administrative_area_level_1': {
                    formikARef.current.setFieldValue('state', component.short_name)

                    break
                }

                case 'country':
                    break
            }
        }
    }

    const getDefaultProfile = () => {
        axios.get('profile/default')
            .then(resp => {
                if (resp.data?.data) {
                    setDefaultProfile(resp.data.data)
                }
            })
    }

    const getProfile = () => {
        axios.get(`profile/${role}/${userId}`)
            .then(resp => {
                setLogo(resp?.data?.data?.profile.logo)
                formikARef.current?.setValues({
                    companyName: resp?.data?.data?.profile.companyName,
                    name4LoanAgreement: resp?.data?.data?.profile.name4LoanAgreement,
                    address: resp?.data?.data?.profile.address,
                    city: resp?.data?.data?.profile.city,
                    state: resp?.data?.data?.profile.state,
                    zip: resp?.data?.data?.profile.zip,
                    phone: resp?.data?.data?.profile.phone,
                    email: resp?.data?.data?.profile.email,
                    logo: resp?.data?.data?.profile.logo
                })
            }).catch(error => {
            if (error.response?.status !== 404) {
                toast.error(error.response?.data?.data?.message)
            }
        })
    }

    const deleteLogo = () => {
        setLogo('')
        formikARef.current.setFieldValue('logo', '')
    }

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        const maxSize = 3 * 1024 * 1024; // 3MB
        if (file && file.size > maxSize) {
            toast.error('File size should be less than 3MB');
        } else if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                formikARef.current.setFieldValue('logo', e.target.result)
                setLogo(e.target.result)
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (values) => {
        // Handle form submission logic here
        axios.put(`profile/${role}/${userId}`, values)
            .then((resp) => {
                toast.success(resp?.data?.message);
                formikARef.current?.resetForm();
                formikARef.current?.setValues({
                    companyName: resp?.data?.data?.profile.companyName,
                    name4LoanAgreement: resp?.data?.data?.profile.name4LoanAgreement,
                    address: resp?.data?.data?.profile.address,
                    city: resp?.data?.data?.profile.city,
                    state: resp?.data?.data?.profile.state,
                    zip: resp?.data?.data?.profile.zip,
                    phone: resp?.data?.data?.profile.phone,
                    email: resp?.data?.data?.profile.email,
                    logo: resp?.data?.data?.profile.logo
                })
                setLogo(resp?.data?.data?.profile.logo)
            }).catch(error => {
            toast.error(error.response?.data?.data?.message)
        })
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Profile
            </Typography>
            <ToastContainer/>
            <Formik
                innerRef={formikARef}
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting}) => {
                    setTimeout(() => {
                        console.log(values)
                        handleSubmit(values).then(() => {
                        }).finally(() => {
                            setSubmitting(false);

                        })
                    }, 400)

                }}
                initialValues={{
                    companyName: undefined,
                    address: undefined,
                    city: undefined,
                    state: undefined,
                    zip: undefined,
                    phone: undefined,
                    email: undefined,
                    logo: undefined
                }}>
                {({
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      isValid
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            {/* Category Main Contracts Inputs */}
                            <Grid item xs={12}>
                                <Typography variant="p"
                                            sx={{color: 'grey', textDecoration: 'underline', fontSize: '18px'}}>Logo
                                    (click
                                    to upload/replace)</Typography>
                                <br/><br/>
                                <Stack direction='row' spacing={2}>
                                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                        <Box position="relative" display="inline-block"
                                             onMouseEnter={() => setIsHovered(true)}
                                             onMouseLeave={() => setIsHovered(false)}
                                        >
                                            <Button component="label">
                                                <input
                                                    id="file-input"
                                                    type="file"
                                                    hidden
                                                    accept="image/*"
                                                    onChange={handleLogoChange}
                                                />
                                                <img src={logo ? logo : defaultProfile.logo} style={{maxHeight: '70px'}}
                                                     alt='logo'/>
                                            </Button>
                                            {logo && isHovered && (
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={deleteLogo}
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 0,
                                                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                                        '&:hover': {
                                                            backgroundColor: 'rgba(255, 255, 255, 1)',
                                                        }
                                                    }}
                                                >
                                                    <DeleteIcon/>
                                                </IconButton>
                                            )}
                                        </Box>
                                        {errors.logo && touched.logo && (
                                            <FormHelperText error><ErrorMessage name={'logo'}/></FormHelperText>

                                        )}
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="p"
                                            sx={{color: 'grey', textDecoration: 'underline', fontSize: '18px'}}>QTM
                                    Contract</Typography>
                                <br/><br/>
                                <Stack direction="row" spacing={2}>
                                    <TextFieldInput
                                        heading="Company Name"
                                        name="companyName"
                                        spanStyle={{
                                            float: 'left',
                                            fontWeight: 'bold',
                                        }}
                                        placeholder={defaultProfile.name}
                                        error={errors.companyName}
                                        touched={touched.companyName}
                                        onChange={handleChange}
                                    />

                                    <TextFieldInput
                                        type='email'
                                        heading="Company Email"
                                        name="email"
                                        spanStyle={{
                                            float: 'left',
                                            fontWeight: 'bold',
                                        }}
                                        placeholder={defaultProfile.email}
                                        error={errors.email}
                                        touched={touched.email}
                                        onChange={handleChange}
                                    />

                                    <TextFieldInput
                                        heading="Company Phone"
                                        name="phone"
                                        spanStyle={{
                                            float: 'left',
                                            fontWeight: 'bold',
                                        }}
                                        error={errors.phone}
                                        touched={touched.phone}
                                        placeholder={defaultProfile.phone}
                                        onChange={(e) => {
                                            const formattedValue = formatPhoneNumber(e.target.value)
                                            formikARef.current.setFieldValue('phone', formattedValue)
                                        }}
                                    />
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack direction="row" spacing={2}>
                                    <TextFieldInput
                                        id='address'
                                        heading="Street Address"
                                        name="address"
                                        spanStyle={{
                                            float: 'left',
                                            fontWeight: 'bold',
                                        }}
                                        placeholder={defaultProfile.addressShort}
                                        error={errors.address}
                                        touched={touched.address}
                                        onChange={handleChange}
                                    />

                                    <TextFieldInput
                                        heading="City"
                                        name="city"
                                        spanStyle={{
                                            float: 'left',
                                            fontWeight: 'bold',
                                        }}
                                        placeholder={defaultProfile.city}
                                        error={errors.city}
                                        touched={touched.city}
                                        onChange={handleChange}
                                    />

                                    <TextFieldInput
                                        heading="State"
                                        name="state"
                                        spanStyle={{
                                            float: 'left',
                                            fontWeight: 'bold',
                                        }}
                                        placeholder={defaultProfile.state}
                                        error={errors.state}
                                        touched={touched.state}
                                        onChange={handleChange}
                                    />

                                    <TextFieldInput
                                        heading="Zip"
                                        name="zip"
                                        spanStyle={{
                                            float: 'left',
                                            fontWeight: 'bold',
                                        }}
                                        placeholder={defaultProfile.zip}
                                        error={errors.zip}
                                        touched={touched.zip}
                                        onChange={handleChange}
                                    />
                                </Stack>

                            </Grid>

                            {/* Category Loan Agreement Inputs */}
                            <Grid item xs={12}>
                                <Typography variant="p"
                                            sx={{color: 'grey', textDecoration: 'underline', fontSize: '18px'}}>Loan
                                    Agreement</Typography>
                                <br/><br/>
                                <Stack direction="row" spacing={2}>
                                    <TextFieldInput
                                        heading="Company Name"
                                        name="name4LoanAgreement"
                                        spanStyle={{
                                            float: 'left',
                                            fontWeight: 'bold',
                                        }}
                                        placeholder={defaultProfile.name4LoanAgreement}
                                        error={errors.name4LoanAgreement}
                                        touched={touched.name4LoanAgreement}
                                        onChange={handleChange}
                                    />
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                {isSubmitting ? (
                                    <CircularProgress/>
                                ) : (
                                    <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                                        Save
                                    </Button>

                                )}
                            </Grid>
                        </Grid>
                    </form>
                )}

            </Formik>

        </Container>
    )
}