import { Grid, TextField } from '@mui/material'
import { ErrorMessage, Field } from 'formik'

export const TextFieldInput = ({
  xs = 12,
  md = 4,
  style = {},
  name,
  error,
  touched,
  spanStyle = { fontWeight: 'bold' },
  heading,
  placeholder,
  onChange,
  type = 'text',
  id,
  disabled = false,
}) => {
  return (
    <Grid item xs={xs} md={md} style={style}>
      <span style={spanStyle}>{heading}</span>
      <Field
        id={id}
        type={type}
        disabled={disabled}
        name={name}
        as={TextField}
        placeholder={placeholder}
        onChange={onChange}
        variant="outlined"
        fullWidth
        margin="normal"
        error={Boolean(error && touched)}
        helperText={<ErrorMessage name={name} />}
      />
    </Grid>
  )
}
