import React from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Container, TextField, Button, CircularProgress, Grid, Typography } from "@mui/material";
import axios from "../utils/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import qbTransparent from "../assets/qbTransparent.png";

const validationSchema = yup.object({
  email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
});

const ManagerLogin = () => {
  const navigate = useNavigate();
  return (
    <>
     <div
        style={{
          marginTop: "3%",
          paddingBottom: "4%",
          borderBottom: "2px solid #E7F5FF",
        }}
      >
        {/* <h1 style={{ textAlign: "center" }}>Sign In</h1> */}
      </div>
      <ToastContainer />
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          height: "56vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img src={qbTransparent} alt="" />
           <Typography sx={{color:"#2c3968", fontWeight:"bold"}} >Welcome to Manager Login</Typography>

          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{ email: "", password: "" }}
            onSubmit={(values, { setSubmitting }) => {
              axios
                .post(`manager/login/`, values)
                .then((data) => {
                  setSubmitting(false);
                  
                  localStorage.setItem("token", data?.data?.data?.token);
                  let role = "manager";
                  localStorage.setItem("role", role);
                  localStorage.setItem("user", JSON.stringify(data?.data?.data?.user));
                  navigate("/");
                })
                .catch((error) => {
                  setSubmitting(false);
                  toast.error(error?.response?.data?.data?.message);
                });
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <div>
                  <span
                    style={{
                      float: "left",
                      fontWeight: "bold",
                      fontSize: "120%",
                    }}
                  >
                    Email*
                  </span>
                  <Field
                    name="email"
                    as={TextField}
                    // label="Email*"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={Boolean(errors.email && touched.email)}
                    helperText={<ErrorMessage name="email" />}
                  />
                </div>

                <div style={{ marginTop: "3%" }}>
                  <span
                    style={{
                      float: "left",
                      fontWeight: "bold",
                      fontSize: "120%",
                    }}
                  >
                    Password*
                  </span>
                  <Field
                    name="password"
                    as={TextField}
                    // label="Password*"
                    variant="outlined"
                    type="password"
                    fullWidth
                    margin="normal"
                    error={Boolean(errors.password && touched.password)}
                    helperText={<ErrorMessage name="password" />}
                    // sx={{border:"2px solid black"}}
                  />
                </div>
                <div>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                    fullWidth
                    sx={{
                      marginTop: "1rem",
                      background: "#6040B0",
                      borderRadius: "7px",
                    }}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <span style={{ fontWeight: "bold", fontSize: "115%" }}>
                        Log In
                      </span>
                    )}
                  </Button>
                  <span
                    style={{
                        float:"right",
                      marginTop: "3%",
                      fontWeight: "bold",
                      cursor: "pointer",
                      
                    }}     onClick={() => {
                      navigate("/forgot-password");
                    }}
                    >
                        Forgot Password?

                  </span>
               
                </div>
                {/* <Grid sx={{  marginTop:"2%" , display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                
                

                </Grid> */}
              
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </>
  );
};

export default ManagerLogin