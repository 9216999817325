import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material'

export const SelectAddendumAndDisclosure = ({
  setUccAddendumChecked,
  uccAddendumChecked,
  gAFLUTDisclosure,
  setGAFLUTDsclosure,
  prePaymentAddendumChecked,
  setPrePaymentAddendumChecked,
  setNyDisclosure,
  nyDisclosure,
  setCaDisclosure,
  caDisclosure,
  KsDsclosure,
  setKsDsclosure,
  isCOntract = true
}) => {
  return (
    <>
      {isCOntract ?
        (<>
          <Grid item xs={12} sx={{ background: '' }} container spacing={3}>
            <Grid item xs={12}>
              <div
                style={{
                  float: 'left',
                  fontWeight: 'bold',
                  fontSize: '120%',
                  marginTop: '2%',
                }}
              >
                Select addendums:{' '}
              </div>
            </Grid>
          </Grid>
          <div
            style={{
              marginTop: '1%',
              marginLeft: '2%',
            }}
          >
            <FormGroup>
              <FormControlLabel
                name='uccAddendumChecked'
                control={
                  <Checkbox
                    onChange={(e) => {
                      // formik?.current?.setFieldValue('uccAddendumChecked', e.target.checked)
                      setUccAddendumChecked(e.target.checked)
                    }}
                  />
                }
                checked={uccAddendumChecked}
                label="UCC and Merchant Addendum"
              />
              <FormControlLabel
                name='prePaymentAddendumChecked'
                control={
                  <Checkbox
                    onChange={(e) => {
                      setPrePaymentAddendumChecked(e.target.checked)
                    }}
                  />
                }
                checked={prePaymentAddendumChecked}
                label="Pre payment Addendum"
              />
            </FormGroup>
          </div>
          <Grid item xs={12}>
            <div
              style={{
                display: 'block',
                float: 'left',
                fontWeight: 'bold',
                fontSize: '120%',
                marginTop: '',
              }}
            >
              Select disclosures:{' '}
            </div>
          </Grid>
          <div
            style={{
              marginTop: '3%',
              marginLeft: '2%',
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      console.log(e.target.checked)
                      setNyDisclosure(e.target.checked)
                    }}
                    checked={nyDisclosure}
                  />
                }
                label="Ny Disclosure"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      console.log(e.target.checked)
                      setCaDisclosure(e.target.checked)
                    }}
                    checked={caDisclosure}
                  />
                }
                label="Ca Disclosure"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      console.log(e.target.checked)
                      setGAFLUTDsclosure(e.target.checked)
                    }}
                    checked={gAFLUTDisclosure}
                  />
                }
                label="GA FL UT Disclosure"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      console.log(e.target.checked)
                      setKsDsclosure(e.target.checked)
                    }}
                    checked={KsDsclosure}
                  />
                }
                label="Ks Disclosure"
              />
            </FormGroup>
          </div>
        </>) :
        (<>
          <Grid item xs={12} sx={{ background: '' }} container spacing={3}>
            <Grid item xs={12}>
              <div
                style={{
                  float: 'left',
                  fontWeight: 'bold',
                  fontSize: '120%',
                  marginTop: '2%',
                }}
              >
                Select addendum:{' '}
              </div>
            </Grid>
          </Grid>
          <div
            style={{
              marginTop: '1%',
              marginLeft: '2%',
            }}
          >
            <FormGroup>
              <FormControlLabel
                name='prePaymentAddendumChecked'
                control={
                  <Checkbox
                    onChange={(e) => {
                      setPrePaymentAddendumChecked(e.target.checked)
                    }}
                  />
                }
                checked={prePaymentAddendumChecked}
                label="Pre payment Addendum"
              />
            </FormGroup>
          </div>
        </>)}
    </>
  )
}
