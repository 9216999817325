import * as yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Container, Button, CircularProgress, Box, Typography, Grid, Checkbox, Modal } from '@mui/material'
import defaultAxios from 'axios'
import axios from '../utils/axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import qbTransparent from '../assets/qbTransparent.png'
import { ReactMultiEmail } from 'react-multi-email'
import { periodicFrequencyValue } from '../utils/helperFunctions'
import { TextFieldInput } from '../components/TextField'
import { SelectFieldInput } from '../components/SelectField'
import { TextCurrencyField } from '../components/TextCurrencyField'
import { TextPercentageField } from '../components/TextPercentageField'
import { SelectAddendumAndDisclosure } from '../components/SelectAddendumAndDisclosure'
import { UCCAddendum } from '../components/UCCAddendum'
import { PrePaymentAddendum } from '../components/PrePaymentAddendum'
import { NYDisclosure } from '../components/NYDisclosure'
import { CADisclosure } from '../components/CADisclosure'
import { GAFLUTDisclosure } from '../components/GAFLUTDisclosure'
import { KsDisclosure } from '../components/KsDisclosure'

yup.addMethod(yup.number, 'maxTwoDecimals', function () {
  return this.test('maxTwoDecimals', 'Must have up to 2 decimal places', function (value) {
    if (value === undefined || value === null) return true; // Allow undefined or null values if not required
    return /^\d+(\.\d{1,2})?$/.test(value);
  });
});

yup.addMethod(yup.number, 'maxTwoDecimals', function () {
  return this.test('maxTwoDecimals', 'Must have up to 2 decimal places', function (value) {
    if (value === undefined || value === null) return true; // Allow undefined or null values if not required
    return /^\d+(\.\d{1,2})?$/.test(value);
  });
});

// Schema definition
const validationSchema = yup.object({
  companyLegalName: yup.string().required('Company Legal Name is required'),
  effectiveDate: yup.string().required('Effective date is required'),
  contractNo: yup.string().required('Contract is required'),
  companyName: yup.string().required('Company Name is required'),
  ein: yup.number().required('EIN is required'),
  streetAddress: yup.string().required('Street address is required'),
  streetAddressCity: yup.string().required('City is required'),
  streetAddressState: yup.string().required('State is required'),
  streetAddressZip: yup.number().required('Zip is required').typeError('Street address Zip must be a number'),
  mailingAddress: yup.string().required('Mailing Address is required'),
  mailingAddressCity: yup.string().required('City is required'),
  mailingAddressState: yup.string().required('State is required'),
  mailingAddressZip: yup.number().required('Zip is required').typeError('Mailing address Zip must be a number'),
  owner1FirstName: yup.string().required('Owner 1 FirstName is required'),
  owner1LastName: yup.string().required('Owner 1 LastName is required'),
  owner1Title: yup.string().required('Owner 1 title is required'),
  owner1Phone: yup.string().required('Owner 1 phone no. is required'),
  owner1Email: yup.string().email('Invalid email format').required('Owner 1 Email is required.'),
  owner2FirstName: yup.string(),
  owner2LastName: yup.string(),
  owner2Title: yup.string(),
  owner2Phone: yup.string(),
  owner2Email: yup.string().email('Invalid email format'),
  nameOfDepositoryInstitution: yup.string().required('Name of Depository Institution is required'),
  sellerABATransit: yup.number().required('ABA/Transit is required').typeError('Seller ABA must be a number'),
  checkingAccount: yup.string().required('Checking account is required'),
  purchasePricePaidToSeller: yup
    .number()
    .required('Purchase Price Paid to Seller is required')
    .maxTwoDecimals()
    .typeError('Purchase Price Paid to Seller must be a number'),
  initialPeriodicAmount: yup
    .number()
    .required('Initial periodic amount is required')
    .maxTwoDecimals()
    .typeError('Initial periodic amount must be a number'),
  purchasedAmmountofFutureReceipts: yup
    .number()
    .required('Purchase amount (Future receipts) is required')
    .maxTwoDecimals()
    .typeError('Purchase amount (Future receipts) must be a number'),
  specifiedPercentage: yup
    .number()
    .required('Specified percentage is required')
    .min(0, 'Specified percentage must be between 0 and 100')
    .max(100, 'Specified percentage must be between 0 and 100')
    .maxTwoDecimals()
    .typeError('Specified percentage must be a number'),
  wireFee: yup
    .number()
    .nullable()
    .optional()
    .maxTwoDecimals()
    .typeError('Wire fee must be a number'),
  priorBalance: yup
    .number()
    .nullable()
    .optional()
    .maxTwoDecimals()
    .typeError('Prior balance must be a number'),
  administrativeFee: yup
    .number()
    .nullable()
    .optional()
    .maxTwoDecimals()
    .typeError('Administrative fee must be a number'),
  processingFee: yup
    .number()
    .nullable()
    .optional()
    .maxTwoDecimals()
    .typeError('Processing fee must be a number'),
  originationFee: yup
    .number()
    .nullable()
    .optional()
    .maxTwoDecimals()
    .typeError('Origination fee must be a number'),
  fbesi: yup.string().required('Form of Business entity is required'),
  periodicFr: yup.string().required('Periodic Frequency is required'),

  prePaymentAddendumChecked: yup.boolean(),
  uccAddendumChecked: yup.boolean(),
  selectedSlots: yup.number().when('prePaymentAddendumChecked', {
    is: true,
    then: yup
      .number()
      .min(1, 'Early Delivery Period Amount Slots is required.')
      .required('Early Delivery Period Amount Slots is required.'),
  }),

  slot0: yup.number().typeError('Slot 1 must be a number').min(0, 'The number must be greater than 0!'),
  slot1: yup.number().typeError('Slot 2 must be a number').min(0, 'The number must be greater than 0!'),
  slot2: yup.number().typeError('Slot 3 must be a number').min(0, 'The number must be greater than 0!'),
  slot3: yup.number().typeError('Slot 4 must be a number').min(0, 'The number must be greater than 0!'),
  slot4: yup.number().typeError('Slot 5 must be a number').min(0, 'The number must be greater than 0!'),
  slot5: yup.number().typeError('Slot 6 must be a number').min(0, 'The number must be greater than 0!'),

  // NY
  NyFA: yup.string().required('Funding Amount is required'),
  NyAPR: yup.string().required('APR is required'),
  NyTM: yup.string().required('Terms in month is required'),
  NyFC: yup.string().required('Finance charge(s) is required'),
  NyTPA: yup.string().required('Estimated Total Payment Amount is required'),
  NyEMC: yup.string().required('Estimated Monthly Cost is required'),
  NyEP: yup.string().required('Estimat Payment(s) is required'),
  NyRemit: yup.string().required('Remit is required'),
  NyET: yup.string().required('Estimated Term is required'),
  NyPrepayment: yup.string().required('Prepayment is required'),
  NyIB: yup.string().required('Internal Balance is required'),
  NyMonthlyAvgRev: yup.string().required('Monthly Average Revenue is required'),
});

export const CreateContract = () => {
  const { id } = useParams()
  const [editable, setEditable] = useState(false)
  const [editData, setEditData] = useState(null)
  const formikARef = useRef()
  const [nyDisclosure, setNyDisclosure] = useState(false)
  const [uccAddendumChecked, setUccAddendumChecked] = useState(false)
  const [caDisclosure, setCaDisclosure] = useState(false)
  const [gAFLUTDisclosure, setGAFLUTDsclosure] = useState(false)
  const [KsDsclosure, setKsDsclosure] = useState(false)
  const [isStreetChecked, setIsStreetChecked] = useState(false)
  const [prePaymentAddendumChecked, setPrePaymentAddendumChecked] = useState(false)
  const [totalContracts, setTotalContracts] = useState(0)
  const [contractSubmit, setContractSubmit] = useState(false)
  const [emails, setEmails] = React.useState([])
  const [emailContractData, setEmailContractData] = useState([])
  const [loader, setLoader] = React.useState(false)
  const [refresh, setRefresh] = useState(false)
  const [open, setOpen] = useState(false)
  const [bussinessEntity, setBussinessEntity] = useState("Limited Liability Company")
  const [periodFrequency, setPeriodFrequency] = useState('Daily')
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [slotsNumber, setSlotsNumber] = useState(1)
  const navigate = useNavigate()
  let address1Field, address2Field, autocomplete, autocomplete1

  useEffect(() => {
    initAutocomplete()
    initAutocomplete2()
  }, [])

  useEffect(() => {
    if (id) setEditable(true)
  }, [id])

  useEffect(() => {
    if (editable) {
      getContract()
    }
  }, [editable])

  const getContract = async () => {
    axios
      .get(`admin/contracts/${id}`)
      .then((data) => {
        setEditData(data?.data?.data)
        setPrePaymentAddendumChecked(data?.data?.data?.preAddendum || false)
        setCaDisclosure(data?.data?.data?.caDisclosure || false)
        setNyDisclosure(data?.data?.data?.nyDisclosure || false)
        setGAFLUTDsclosure(data?.data?.data?.gAFLUTDisclosure || false)
        setKsDsclosure(data?.data?.data?.KsDsclosure || false)
        setSlotsNumber(data?.data?.data?.selectedSlots || 1)
        setUccAddendumChecked(data?.data?.data?.uccAddendum || false)
        setPeriodFrequency(data?.data?.data?.periodicFr || "Weekly")
        setBussinessEntity(data?.data?.data?.fbesi || "Limited Liability Company")
      })
      .catch((error) => {
        console.log({ error })
        toast.error(error.response.data.data.message)
      })
  }

  function initAutocomplete() {
    address1Field = document.querySelector('#main-address')
    autocomplete = new window.google.maps.places.Autocomplete(address1Field, {
      componentRestrictions: { country: ['us'] },
      fields: ['address_components', 'geometry'],
      types: ['address'],
    })
    autocomplete.addListener('place_changed', fillInAddress)
  }

  function initAutocomplete2() {
    address2Field = document.querySelector('#mail-address')
    autocomplete1 = new window.google.maps.places.Autocomplete(address2Field, {
      componentRestrictions: { country: ['us'] },
      fields: ['address_components', 'geometry'],
      types: ['address'],
    })
    autocomplete1.addListener('place_changed', fillInAddress2)
  }

  function fillInAddress() {
    const place = autocomplete.getPlace()
    let address1 = ''
    let postcode = ''
    let st = ''

    for (const component of place.address_components) {
      const componentType = component.types[0]
      switch (componentType) {
        case 'street_number': {
          st = component.long_name
          console.log('street_number', `${component.long_name} ${address1}`)
          break
        }
        case 'route': {
          const addressDetails = st + ' ' + component.short_name
          formikARef.current.setFieldValue('streetAddress', addressDetails)
          if (isStreetChecked) formikARef.current.setFieldValue('mailingAddress', addressDetails)
          break
        }

        case 'postal_code': {
          postcode = component.long_name
          formikARef.current.setFieldValue('streetAddressZip', postcode)
          if (isStreetChecked) formikARef.current.setFieldValue('mailingAddressZip', component.long_name)
          break
        }

        case 'postal_code_suffix': {
          break
        }

        case 'locality':
          formikARef.current.setFieldValue('streetAddressCity', component.long_name)
          if (isStreetChecked) formikARef.current.setFieldValue('mailingAddressCity', component.long_name)

          break

        case 'administrative_area_level_1': {
          formikARef.current.setFieldValue('streetAddressState', component.short_name)
          if (isStreetChecked) formikARef.current.setFieldValue('mailingAddressState', component.short_name)
          if (component.short_name === 'NY') {
            setNyDisclosure(true)
          }

          if (component.short_name === 'CA') {
            setCaDisclosure(true)
          }
          if (component.short_name === 'GA' || component.short_name === 'FL' || component.short_name === 'UT') {
            setGAFLUTDsclosure(true)
          }
          break
        }

        case 'country':
          break
      }
    }
  }

  function fillInAddress2() {
    const place = autocomplete1.getPlace()
    let address2 = ''
    let postcode = ''
    let st = ''

    for (const component of place.address_components) {
      const componentType = component.types[0]

      switch (componentType) {
        case 'street_number': {
          st = component.long_name
          break
        }

        case 'route': {
          formikARef.current.setFieldValue('mailingAddress', st + ' ' + component.short_name)
          break
        }

        case 'postal_code': {
          postcode = component.long_name

          formikARef.current.setFieldValue('mailingAddressZip', postcode)

          break
        }

        case 'postal_code_suffix': {
          console.log('postal_code_suffix', `${postcode}-${component.long_name}`)
          // postcode = `${postcode}-${component.long_name}`;
          break
        }

        case 'locality':
          console.log('locality', component.long_name)
          // document.querySelector("#main-city").value = component.long_name;
          formikARef.current.setFieldValue('mailingAddressCity', component.long_name)
          break

        case 'administrative_area_level_1': {
          console.log('administrative_area_level_1', component.short_name)
          // document.querySelector("#main-state").value = component.short_name;
          formikARef.current.setFieldValue('mailingAddressState', component.short_name)
          if (component.short_name === 'NY') {
            setNyDisclosure(true)
          }

          if (component.short_name === 'CA') {
            setCaDisclosure(true)
          }
          if (component.short_name === 'GA' || component.short_name === 'FL' || component.short_name === 'UT') {
            setGAFLUTDsclosure(true)
          }
          break
        }

        case 'country':
          console.log('country', component.long_name)
          // document.querySelector("#country").value = component.long_name;
          break
      }
    }
  }
  const updateGuarantorAndPre = (firstName, lastName) => {
    formikARef.current.setFieldValue('guarantor', (firstName || '') + ' ' + (lastName || ''))
    formikARef.current.setFieldValue('guarantorpre', (firstName || '') + ' ' + (lastName || ''))
  }

  const handleBussinessChange = (event) => {
    setBussinessEntity(event.target.value)
    formikARef.current.setFieldValue('fbesi', event.target.value)
  }

  const handlePeriodicChange = (event) => {
    setPeriodFrequency(event.target.value)
    formikARef.current.setFieldValue('periodicFr', event?.target?.value)
    formikARef.current.setFieldValue('Gafruquency', event?.target?.value)
    formikARef.current.setFieldValue('Nyfruquency', event?.target?.value)
    formikARef.current.setFieldValue('Cafruquency', event?.target?.value)
    formikARef.current.setFieldValue('Ksfruquency', event?.target?.value)
    formikARef.current.setFieldValue(
      'NyEMC',
      formikARef?.current?.values?.initialPeriodicAmount * (periodicFrequencyValue[event.target.value] || 0)
    )
    formikARef.current.setFieldValue(
      'CaEMC',
      formikARef?.current?.values?.initialPeriodicAmount * (periodicFrequencyValue[event.target.value] || 0)
    )
  }
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } }
  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/\D/g, '') // Remove non-digit characters
    if (phoneNumber.length <= 3) {
      return phoneNumber
    } else if (phoneNumber.length <= 6) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`
    } else {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
    }
  }

  useEffect(() => {
    axios
      .get(`admin/contracts?size=${20}&page=${1}`)
      .then((data) => {
        setTotalContracts(data?.data?.data?.totalContracts)
      })
      .catch((error) => {
        toast.error(error.response?.data?.data?.message)
      })
  }, [refresh])

  const handleFormSubmit = async () => {
    setContractSubmit(true)
    let data = {
      id: +id || null,
      periodicFr: formikARef.current.values.periodicFr,
      companyLegalName: formikARef.current.values.companyLegalName,
      contractNo: formikARef?.current?.values?.contractNo,
      effectiveDate: formikARef.current.values.effectiveDate,
      companyName: formikARef.current.values.companyName,
      ein: formikARef.current.values.ein,
      fbesi: formikARef.current.values.fbesi,
      streetAddress: formikARef.current.values.streetAddress,
      streetAddressCity: formikARef.current.values.streetAddressCity,
      streetAddressState: formikARef.current.values.streetAddressState,
      streetAddressZip: formikARef.current.values.streetAddressZip,
      mailingAddress: formikARef.current.values.mailingAddress,
      mailingAddressCity: formikARef.current.values.mailingAddressCity,
      mailingAddressState: formikARef.current.values.mailingAddressState,
      mailingAddressZip: formikARef.current.values.mailingAddressZip,
      owner1FirstName: formikARef.current.values.owner1FirstName,
      owner1LastName: formikARef.current.values.owner1LastName,
      owner1Title: formikARef.current.values.owner1Title,
      owner1Phone: formikARef.current.values.owner1Phone,
      owner1Email: formikARef.current.values.owner1Email,
      owner2FirstName: formikARef.current.values.owner2FirstName,
      owner2LastName: formikARef.current.values.owner2LastName,
      owner2Title: formikARef.current.values.owner2Title,
      owner2Phone: formikARef.current.values.owner2Phone,
      owner2Email: formikARef.current.values.owner2Email,
      primaryContactName: formikARef.current.values.primaryContactName,
      primaryContactTitle: formikARef.current.values.primaryContactTitle,
      primaryContactPhoneNo: formikARef.current.values.primaryContactPhoneNo,
      primaryContactEmail: formikARef.current.values.primaryContactEmail,
      ndi: formikARef.current.values.nameOfDepositoryInstitution,
      abaSeller: formikARef.current.values.sellerABATransit,
      checkingAccount: formikARef.current.values.checkingAccount,
      purchasePriceSeller: +formikARef?.current?.values?.purchasePricePaidToSeller || "0",
      purchaseAmountFuture: +formikARef?.current?.values?.purchasedAmmountofFutureReceipts || 0,
      initialPeriodicAmount: +formikARef?.current?.values?.initialPeriodicAmount || 0,
      specifiedPercentage: formikARef.current.values.specifiedPercentage,
      priorBalance: +formikARef?.current?.values?.priorBalance || 0,
      wireFee: +formikARef.current.values.wireFee || 0,
      administrativeFee: +formikARef.current.values.administrativeFee,
      processingFee: +formikARef?.current?.values?.processingFee || 0,
      originationFee: +formikARef?.current?.values?.originationFee || 0,
      netAmount:
        +formikARef.current.values.purchasePricePaidToSeller -
        +formikARef.current.values.priorBalance -
        +formikARef.current.values.processingFee -
        +formikARef.current.values.originationFee -
        +formikARef.current.values.wireFee,
      sellerName: formikARef.current.values.firstName,
      bankName: formikARef.current.values.nameOfDepositoryInstitution,
      abaBank: formikARef.current.values.sellerABATransit,
      accountNo: formikARef.current.values.checkingAccount,
      uccAddendumChecked,
      prePaymentAddendumChecked,
      nyDisclosure,
      caDisclosure,
      gAFLUTDisclosure,
      KsDsclosure,


      selectedSlots: formikARef?.current?.values?.selectedSlots,
      slot0: formikARef?.current?.values?.slot0,
      slot1: formikARef?.current?.values?.slot1,
      slot2: formikARef?.current?.values?.slot2,
      slot3: formikARef?.current?.values?.slot3,
      slot4: formikARef?.current?.values?.slot4,
      slot5: formikARef?.current?.values?.slot5,
      // caDisclosure,
      // gAFLUTDisclosure,

      NyIB: +formikARef?.current?.values?.NyIB,
      NyEP: +formikARef?.current?.values?.NyEP,
      NyAPR: +formikARef?.current?.values?.NyAPR || 0,
      NyMonthlyAvgRev: +formikARef?.current?.values?.NyMonthlyAvgRev,
      NyFC:
        +parseFloat((+formikARef?.current?.values?.purchasedAmmountofFutureReceipts || 0) -
          (+formikARef?.current?.values?.purchasePricePaidToSeller || 0) + (+formikARef?.current?.values?.wireFee || 0) +
          (+formikARef?.current?.values?.processingFee || 0) +
          ((+formikARef?.current?.values?.administrativeFee || 0) * (+formikARef?.current?.values?.NyTM || 0))).toFixed(2),
      NyEMC:
        +parseFloat((formikARef?.current?.values?.NyEP || 0) *
          (periodicFrequencyValue[formikARef?.current?.values?.periodicFr] || 0)).toFixed(2),
      NyTM: +formikARef?.current?.values?.NyTM,
      NyET: +formikARef?.current?.values?.NyTM * 30,
      NyPrepayment:
        +parseFloat(+formikARef?.current?.values?.purchasedAmmountofFutureReceipts -
          +formikARef?.current?.values?.purchasePricePaidToSeller +
          (+formikARef?.current?.values?.administrativeFee * +formikARef?.current?.values?.NyTM || 0)).toFixed(2),
      Nyname: formikARef?.current?.values?.Nyname,
      NyAmount: +formikARef?.current?.values?.NyAmount,
      Nyname2: formikARef?.current?.values?.Nyname2,
      NyAmount2: +formikARef?.current?.values?.NyAmount2,
      Nyname3: formikARef?.current?.values?.Nyname3,
      NyAmount3: +formikARef?.current?.values?.NyAmount3,
      Nyfruquency: formikARef.current.values.periodicFr,

      CaAPR: +formikARef?.current?.values?.CaAPR,
      CaTM: +formikARef?.current?.values?.CaTM,
      CaEMC:
        +((+formikARef?.current?.values?.CaEP || 0) *
          (periodicFrequencyValue[formikARef?.current?.values?.periodicFr] || 0)).toFixed(2),
      CaET: +formikARef?.current?.values?.CaTM * 30 || 0,
      CaFC:
        +((+formikARef?.current?.values?.purchasedAmmountofFutureReceipts || 0) -
          (+formikARef?.current?.values?.purchasePricePaidToSeller || 0) + (+formikARef?.current?.values?.wireFee || 0) +
          (+formikARef?.current?.values?.processingFee || 0) +
          ((+formikARef?.current?.values?.administrativeFee || 0) * (+formikARef?.current?.values?.CaTM || 0))).toFixed(2),
      CaPrepayment:
        +(+formikARef?.current?.values?.purchasedAmmountofFutureReceipts -
          +formikARef?.current?.values?.purchasePricePaidToSeller +
          (+formikARef?.current?.values?.administrativeFee * +formikARef?.current?.values?.CaTM || 0)).toFixed(2),
      CaIB: +formikARef.current.values.CaIB,
      CaEP: +formikARef.current.values.CaEP,
      Caname: formikARef.current.values.Caname,
      CaAmount: +formikARef.current.values.CaAmount,
      Caname2: formikARef.current.values.Caname2,
      CaAmount2: +formikARef.current.values.CaAmount2,
      Caname3: formikARef.current.values.Caname3,
      CaAmount3: +formikARef.current.values.CaAmount3,
      CaMonthlyAvgRev: +formikARef.current.values.CaMonthlyAvgRev,

      // Ga Disclosure
      GaIB: +formikARef?.current?.values?.GaIB,
      GaTM: +formikARef?.current?.values?.GaTM,
      GaADFP:
        +(+formikARef?.current?.values?.originationFee +
          +formikARef?.current?.values?.wireFee +
          +formikARef?.current?.values?.processingFee +
          +formikARef?.current?.values?.GaIB +
          (+formikARef?.current?.values?.GaAmount +
            +formikARef?.current?.values?.GaAmount2 +
            +formikARef?.current?.values?.GaAmount3)).toFixed(2),

      GaNA:
        +((+formikARef.current.values.purchasePricePaidToSeller) -
          (+formikARef.current.values.priorBalance) -
          (+formikARef.current.values.processingFee) -
          (+formikARef.current.values.wireFee) -
          (+formikARef.current.values.originationFee)).toFixed(2),
      GaTDC:
        +((+formikARef?.current?.values?.purchasedAmmountofFutureReceipts -
          +formikARef?.current?.values?.purchasePricePaidToSeller) +
          (+formikARef?.current?.values?.administrativeFee * +formikARef?.current?.values?.GaTM || 0)).toFixed(2),
      GaTAP:
        +((+formikARef?.current?.values?.purchasedAmmountofFutureReceipts || 0) +
          ((+formikARef?.current?.values?.administrativeFee || 0) * (+formikARef?.current?.values?.GaTM || 0))).toFixed(2),


      Ganame: formikARef.current.values.Ganame,
      GaAmount: +formikARef.current.values.GaAmount,
      Ganame2: formikARef.current.values.Ganame2,
      GaAmount2: +formikARef.current.values.GaAmount2,
      Ganame3: formikARef.current.values.Ganame3,
      GaAmount3: +formikARef.current.values.GaAmount3,
      Gafruquency: formikARef.current.values.periodicFr,

      // ks disclosure
      KsIB: +formikARef?.current?.values?.KsIB,
      KsTM: +formikARef?.current?.values?.KsTM,
      KsADFP:
        +(+formikARef?.current?.values?.originationFee +
          +formikARef?.current?.values?.wireFee +
          +formikARef?.current?.values?.processingFee +
          +formikARef?.current?.values?.KsIB +
          (+formikARef?.current?.values?.KsAmount +
            +formikARef?.current?.values?.KsAmount2 +
            +formikARef?.current?.values?.KsAmount3)).toFixed(2),
      KsNA:
        +((+formikARef.current.values.purchasePricePaidToSeller) -
          (+formikARef.current.values.priorBalance) -
          (+formikARef.current.values.processingFee) -
          (+formikARef.current.values.wireFee) -
          (+formikARef.current.values.originationFee)).toFixed(2),
      KsTDC:
        +(((+formikARef?.current?.values?.purchasedAmmountofFutureReceipts -
          +formikARef?.current?.values?.purchasePricePaidToSeller)) +
          (+formikARef?.current?.values?.originationFee) + (+formikARef?.current?.values?.wireFee || 0) + (+formikARef?.current?.values?.processingFee || 0)).toFixed(2),
      KsTAP:
        +((+formikARef?.current?.values?.purchasedAmmountofFutureReceipts || 0) +
          ((+formikARef?.current?.values?.administrativeFee || 0) * (+formikARef?.current?.values?.KsTM || 0))).toFixed(2),
      Ksname: formikARef.current.values.Ksname,
      KsAmount: +formikARef.current.values.KsAmount,
      Ksname2: formikARef.current.values.Ksname2,
      KsAmount2: +formikARef.current.values.KsAmount2,
      Ksname3: formikARef.current.values.Ksname3,
      KsAmount3: +formikARef.current.values.KsAmount3,
      Ksfruquency: formikARef.current.values.periodicFr,
    }
    console.log({ data })
    axios
      .post(`createContractPDF`, data)
      .then((data) => {
        setContractSubmit(false)
        toast.success('Contract Created Successfully')
        formikARef.current?.resetForm()
        setIsStreetChecked(false)
        setGAFLUTDsclosure(false)
        setBussinessEntity(false)
        setPeriodFrequency(false)
        setNyDisclosure(false)
        setCaDisclosure(false)
        setKsDsclosure(false)
        setRefresh(!refresh)
        handleOpen()
        setEmailContractData(data?.data?.data?.pdfData)
      })
      .catch((error) => {
        setContractSubmit(false)
        toast.error(error.response.data.data.message)
      })
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    outline: 'none',
  }

  const [documentStatus, setDocumentStatus] = useState({
    data: '',
    status: '',
  })
  useEffect(() => {
    if (documentStatus.status === 'document.uploaded') {
      callDoctumentStatus()
    }
  }, [documentStatus.status])

  function callDoctumentStatus() {
    defaultAxios
      .get(`https://api.pandadoc.com/public/v1/documents/${documentStatus?.data?.data?.id}`, {
        headers: {
          Authorization: `API-Key 9731e671eae19ec4816d9cf423824e451a85e073`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((data) => {
        const status = data?.data?.status
        if (status === 'document.draft') {
          setDocumentStatus('')
          console.log('Document is now in draft status!')
          defaultAxios
            .post(
              `https://api.pandadoc.com/public/v1/documents/${data?.data.id}/send`,
              {
                message: 'Hello, here is a new Funding Agreement for your review',
              },
              {
                headers: {
                  Authorization: `API-Key 9731e671eae19ec4816d9cf423824e451a85e073`,
                  'Content-Type': 'application/json',
                },
              }
            )
            .then((data) => {
              toast.success('Email sent')
              handleClose()
              setLoader(false)
              setOpen(false)
              setEmails([])
              if (emails.length !== 0) {
                axios
                  .post(`addRecipients`, {
                    recipients: JSON.stringify(emails),
                    contractId: emailContractData.id,
                    pandaDocId: data?.data?.id,
                  })
                  .then(() => { })
                  .catch((error) => {
                    console.log(error)
                  })
              }
            })
            .catch((error) => {
              toast.error('An error occurred while sending email')

              console.log('error')
              setLoader(false)
              handleClose()
              setEmails([])
            })
        } else if (status === 'document.uploaded') {
          console.log('Document is still in uploaded status. Checking again...')
          setTimeout(callDoctumentStatus, 5000) // Poll every 5 seconds (adjust as needed)
        }
      })
      .catch((error) => {
        console.error('Error fetching document status:', error)
      })
  }

  const handleOwnerEmailSend = async () => {
    setLoader(true)
    let recipientsData = []

    if (emailContractData.type == 'Contract') {
      if (emailContractData.owner2Email !== '') {
        recipientsData = [
          {
            email: emailContractData.owner1Email,
            role: 'owner1',
          },
          {
            email: emailContractData.owner2Email,
            role: 'owner2',
          },
        ]

        emails.map((d, i) => {
          recipientsData.push({
            email: d,
            role: `user${i}`,
          })
        })
      } else {
        recipientsData = [
          {
            email: emailContractData.owner1Email,
            role: 'owner1',
          },
        ]

        emails.map((d, i) => {
          recipientsData.push({
            email: d,
            role: `user${i}`,
          })
        })
      }
    } else {
      emails.map((d, i) => {
        recipientsData.push({
          email: d,
          role: `user${i}`,
        })
      })
    }

    let data = {
      name: `${emailContractData.contractName} (${emailContractData.companyName})`,
      url: emailContractData.link,
      recipients: recipientsData,
      parse_form_fields: false,
    }

    defaultAxios
      .post(`https://api.pandadoc.com/public/v1/documents`, data, {
        headers: {
          Authorization: `API-Key 9731e671eae19ec4816d9cf423824e451a85e073`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((data) => {
        let docStatus = data?.data?.status
        console.log('docSTatus', docStatus)
        setDocumentStatus({ data: data, status: 'document.uploaded' })
      })
      .catch((error) => {
        toast.error('An error occurred')
        setLoader(false)
        handleClose()
      })
  }

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ outline: 'none' }}
        >
          <Box sx={modalStyle}>
            <h2>Send Contract</h2>
            Email will be sent to following addresses:
            <div style={{ marginTop: '5%' }}>
              <b>Owner 1</b>: {emailContractData?.owner1Email}
            </div>
            {emailContractData?.owner2Email !== '' && (
              <div style={{ marginTop: '5%' }}>
                <b>Owner 2</b>: {emailContractData?.owner2Email}
              </div>
            )}
            <div style={{ marginTop: '5%' }}>
              <form>
                <h3>Recipients</h3>
                <ReactMultiEmail
                  placeholder="Enter email and press enter"
                  emails={emails}
                  onChange={(_emails) => {
                    setEmails(_emails)
                  }}
                  autoFocus={true}
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div data-tag key={index}>
                        <div data-tag-item>{email}</div>
                        <span data-tag-handle onClick={() => removeEmail(index)}>
                          ×
                        </span>
                      </div>
                    )
                  }}
                />
                <br />
              </form>
              {loader ? (
                <>
                  <CircularProgress sx={{ marginTop: '5%' }} />
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      marginTop: '5%',
                      background: '#6040B0',
                    }}
                    onClick={() => {
                      handleOwnerEmailSend()
                    }}
                  >
                    Send
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      marginTop: '5%',
                      background: '#f44336',
                      marginLeft: '5%',
                    }}
                    onClick={() => {
                      handleClose()
                      navigate('/')
                    }}
                  >
                    Close
                  </Button>
                </>
              )}
            </div>
          </Box>
        </Modal>
      </div>

      {!id &&
        <Grid
          xs={12}
          style={{
            width: '100%',
            marginTop: '2%',
            paddingBottom: '1%',
            boxShadow: '10px 1px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: '2%',
            paddingLeft: '2%',
          }}
        >
          <Grid xs={6}>
            <img
              src={qbTransparent}
              alt=""
              style={{
                height: '13vh',
                marginTop: '-2%',
                float: 'left',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/')
              }}
            />
          </Grid>
          <Grid xs={6}>
            <h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>Create QTM Contract</h1>
          </Grid>
        </Grid>
      }

      <ToastContainer />
      <Container maxWidth>
        {id ? <Typography
          variant="h5"
          sx={{
            marginY: '3%',
            marginLeft: { md: '4.5rem', sm: '0.5em' },
          }}
        >
          Editing Contract
        </Typography> : <Typography
          variant="h5"
          sx={{
            marginY: '3%',
            marginLeft: { md: '4.5rem', sm: '0.5em' },
          }}
        >
          Create a new contract
        </Typography>}

        <Formik
          innerRef={formikARef}
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            id: editData?.id,
            companyLegalName: editData?.companyLegalName || '',
            effectiveDate: editData?.effectiveDate || '',
            date: editData?.effectiveDate || '',
            contractNo: editData?.contractName || `QTM-Contract-${totalContracts + 1}`,
            companyName: editData?.companyName || '',
            ein: editData?.ein || '',
            streetAddress: editData?.streetAddress || '',
            streetAddressCity: editData?.streetAddressCity || '',
            streetAddressState: editData?.streetAddressState || '',
            streetAddressZip: editData?.streetAddressZip || '',
            mailingAddress: editData?.mailingAddress || '',
            mailingAddressCity: editData?.mailingAddressCity || '',
            mailingAddressState: editData?.mailingAddressState || '',
            mailingAddressZip: editData?.mailingAddressZip || '',
            owner1FirstName: editData?.owner1FirstName || '',
            owner1LastName: editData?.owner1LastName || '',
            owner1Title: editData?.owner1Title || '',
            owner1Phone: editData?.owner1Phone || '',
            owner1Email: editData?.owner1Email || '',
            owner2FirstName: editData?.owner2FirstName || '',
            owner2LastName: editData?.owner2LastName || '',
            owner2Title: editData?.owner2Title || '',
            owner2Phone: editData?.owner2Phone || '',
            owner2Email: editData?.owner2Email || '',
            nameOfDepositoryInstitution: editData?.ndi || '',
            sellerABATransit: editData?.abaSeller || '',
            checkingAccount: editData?.checkingAccount || '',
            purchasePricePaidToSeller: editData?.purchasePriceSeller || 0,
            initialPeriodicAmount: editData?.initialPeriodicAmount || 0,
            purchasedAmmountofFutureReceipts: editData?.purchaseAmountFuture || 0,
            specifiedPercentage: editData?.specifiedPercentage || 0,
            priorBalance: editData?.priorBalance || 0,
            wireFee: editData?.wireFee || 0,
            administrativeFee: editData?.administrativeFee || 0,
            processingFee: editData?.processingFee || 0,
            originationFee: editData?.originationFee || 0,
            netAmountSeller: editData?.netAmountSeller || 0,
            seller: editData?.companyLegalName || '',
            nameOfBank: editData?.bankName || '',
            abaTransit: editData?.abaBank || '',
            accountNo: editData?.accountNo || '',
            fbesi: editData?.fbesi || 'Limited Liability Company',
            periodicFr: editData?.periodicFr || 'Daily',
            Gafruquency: editData?.periodicFr || 'Daily',
            Nyfruquency: editData?.periodicFr || 'Daily',
            // Pre payment
            guarantor: editData?.owner1FirstName + ' ' + editData?.owner1LastName || '',
            merchant: editData?.companyLegalName || '',
            legalEntity: editData?.companyLegalName,
            selectedSlots: editData?.selectedSlots || 1,
            slot0: editData?.slot0 || '0',
            slot1: editData?.slot1 || '0',
            slot2: editData?.slot2 || '0',
            slot3: editData?.slot3 || '0',
            slot4: editData?.slot4 || '0',
            slot5: editData?.slot5 || '0',
            //ucc
            guarantorpre: editData?.owner1FirstName + ' ' + editData?.owner1LastName || '',
            merchantpre: editData?.companyLegalName || '',
            // NY
            NyFA: editData?.NyFA || 0,
            NyAPR: editData?.NyAPR || 0,
            NyTM: editData?.NyTM || 0,
            NyFC: editData?.NyFC || 0,
            NyTPA: editData?.purchaseAmountFuture || 0,
            NyEMC: editData?.NyEMC || 0,
            NyEP: editData?.NyEP || 0,
            NyRemit: editData?.specifiedPercentage || 0,
            NyET: editData?.NyET || 0,
            NyPrepayment: editData?.NyPrepayment || 0,
            NyIB: editData?.NyIB || 0,
            NyMonthlyAvgRev: editData?.NyMonthlyAvgRev || 0,
            Nyname: editData?.Nyname || '',
            NyAmount: editData?.NyAmount || 0,
            Nyname2: editData?.Nyname2 || '',
            NyAmount2: editData?.NyAmount2 || 0,
            Nyname3: editData?.Nyname3 || '',
            NyAmount3: editData?.NyAmount3 || 0,

            CaAPR: editData?.CaAPR || 0,
            CaTM: editData?.CaTM || 0,
            CaET: editData?.CaET || 0,
            CaEP: editData?.CaEP || 0,
            CaIB: editData?.CaIB || 0,
            CaEMC: editData?.CaEMC || 0,
            CaTPA: editData?.purchaseAmountFuture || 0,
            CaFC: editData?.CaFC || 0,
            CaPrepayment: editData?.CaPrepayment || 0,
            Cafruquency: editData?.periodicFr || 0,
            CaMonthlyAvgRev: editData?.CaMonthlyAvgRev || 0,
            Caname: editData?.Caname || '',
            CaAmount: editData?.CaAmount || 0,
            Caname2: editData?.Caname2 || '',
            CaAmount2: editData?.CaAmount2 || 0,
            Caname3: editData?.Caname3 || '',
            CaAmount3: editData?.CaAmount3 || 0,

            // GA disclosure
            GaIB: editData?.GaIB || 0,
            GaTM: editData?.GaTM || 0,
            Ganame: editData?.Ganame || '',
            GaAmount: editData?.GaAmount || 0,
            Ganame2: editData?.Ganame2 || '',
            GaAmount2: editData?.GaAmount2 || 0,
            Ganame3: editData?.Ganame3 || '',
            GaAmount3: editData?.GaAmount3 || 0,

            // Ks disclosure
            KsTAP : editData?.KsTAP || 0,
            KsIB: editData?.KsIB || 0,
            KsTM: editData?.KsTM || 0,
            Ksname: editData?.Ksname || '',
            KsAmount: editData?.KsAmount || 0,
            Ksname2: editData?.Ksname2 || '',
            KsAmount2: editData?.KsAmount2 || 0,
            Ksname3: editData?.Ksname3 || '',
            KsAmount3: editData?.KsAmount3 || 0,
            Ksfruquency: editData?.periodicFr || ""
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setContractSubmit(true)
            handleFormSubmit()
          }}
        >
          {({ errors, touched, values }) => (
            <Form>
              <Container>
                <Grid item xs={12} sx={{ background: '' }} container spacing={3}>
                  <TextFieldInput
                    disabled
                    name="contractNo"
                    error={errors.contractNo}
                    touched={touched.contractNo}
                    spanStyle={{ fontWeight: 'bold' }}
                    heading="Contract No."
                  />
                  <TextFieldInput
                    name="effectiveDate"
                    error={errors.effectiveDate}
                    touched={touched.effectiveDate}
                    spanStyle={{ fontWeight: 'bold' }}
                    heading="Effective Date"
                    onChange={(e) => {
                      const { value } = e.target
                      formikARef.current.setFieldValue('effectiveDate', value)
                      formikARef.current.setFieldValue('date', value)
                    }}
                    id="start-date"
                    type="date"
                  />
                  <SelectFieldInput
                    name={'periodicFr'}
                    heading={'Periodic Frequency'}
                    onChange={handlePeriodicChange}
                    selected={periodFrequency}
                    required
                    options={[
                      {
                        value: 'Daily',
                        label: 'Daily',
                      },
                      {
                        value: 'Weekly',
                        label: 'Weekly',
                      },
                      {
                        value: 'Bi-Weekly',
                        label: 'Bi-Weekly',
                      },
                      {
                        value: 'Monthly',
                        label: 'Monthly',
                      },
                      {
                        value: 'Variable',
                        label: 'Variable',
                      },
                    ]}
                  />
                  <TextFieldInput
                    md={3}
                    name="companyLegalName"
                    error={errors.companyLegalName}
                    touched={touched.companyLegalName}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="Company Legal Name"
                    onChange={(e) => {
                      const { value } = e.target
                      formikARef.current.setFieldValue('companyLegalName', value)
                      formikARef.current.setFieldValue('seller', value)
                      formikARef.current.setFieldValue('merchant', value)
                      formikARef.current.setFieldValue('merchantpre', value)
                      formikARef.current.setFieldValue('legalEntity', value)
                    }}
                  />
                  <TextFieldInput
                    md={3}
                    name="companyName"
                    error={errors.companyName}
                    touched={touched.companyName}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="Company Name (D/B/A)"
                    onChange={(e) => {
                      const { value } = e.target
                      formikARef.current.setFieldValue('companyName', value)
                    }}
                  />
                  <SelectFieldInput
                    md={3}
                    name={'fbesi'}
                    heading={'Form of Business Entity'}
                    onChange={handleBussinessChange}
                    selected={bussinessEntity}
                    options={[
                      {
                        value: 'Limited Liability Company',
                        label: 'Limited Liability Company',
                      },
                      {
                        value: 'Corporation',
                        label: 'Corporation',
                      },
                      {
                        value: 'Sole Proprietor',
                        label: 'Sole Proprietor',
                      },
                      {
                        value: 'Partnership',
                        label: 'Partnership',
                      },
                    ]}
                  />

                  <TextFieldInput
                    md={3}
                    name="ein"
                    error={errors.ein}
                    touched={touched.ein}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="EIN"
                    onChange={(e) => {
                      const { value } = e.target
                      formikARef.current.setFieldValue('ein', value)
                    }}
                    type="number"
                  />

                  <TextFieldInput
                    md={3}
                    name="streetAddress"
                    error={errors.streetAddress}
                    touched={touched.streetAddress}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="Street Address"
                    onChange={(e) => {
                      formikARef.current.setFieldValue('streetAddress', e.target.value)
                      if (isStreetChecked) formikARef.current.setFieldValue('mailingAddress', e.target.value)
                    }}
                    id="main-address"
                  />

                  <TextFieldInput
                    md={3}
                    name="streetAddressCity"
                    error={errors.streetAddressCity}
                    touched={touched.streetAddressCity}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="City"
                    onChange={(e) => {
                      formikARef.current.setFieldValue('streetAddressCity', e.target.value)
                      if (isStreetChecked) formikARef.current.setFieldValue('mailingAddressCity', e.target.value)
                    }}
                    id="main-city"
                  />

                  <TextFieldInput
                    md={3}
                    name="streetAddressState"
                    error={errors.streetAddressState}
                    touched={touched.streetAddressState}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="State"
                    onChange={(e) => {
                      formikARef.current.setFieldValue('streetAddressState', e.target.value)
                    }}
                    id="main-state"
                  />

                  <TextFieldInput
                    md={3}
                    name="streetAddressZip"
                    error={errors.streetAddressZip}
                    touched={touched.streetAddressZip}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="Zip"
                    onChange={(e) => {
                      formikARef.current.setFieldValue('streetAddressZip', e.target.value)
                    }}
                    id="main-code"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Checkbox
                      checked={isStreetChecked}
                      onChange={(e) => {
                        setIsStreetChecked(e.target.checked)

                        formikARef.current.setFieldValue('mailingAddress', formikARef.current.values?.streetAddress)
                        formikARef.current.setFieldValue(
                          'mailingAddressCity',
                          formikARef.current.values?.streetAddressCity
                        )
                        formikARef.current.setFieldValue(
                          'mailingAddressState',
                          formikARef.current.values?.streetAddressState
                        )
                        formikARef.current.setFieldValue(
                          'mailingAddressZip',
                          formikARef.current.values?.streetAddressZip
                        )
                      }}
                      {...label}
                    />
                    <p>Same as street address</p>
                  </Box>
                </Grid>

                <Grid item xs={12} sx={{ background: '' }} container spacing={3}>
                  <TextFieldInput
                    md={3}
                    name="mailingAddress"
                    error={errors.mailingAddress}
                    touched={touched.mailingAddress}
                    disabled={isStreetChecked}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="Mailing Address"
                    onChange={(e) => {
                      formikARef.current.setFieldValue('mailingAddress', e.target.value)
                    }}
                    id="mail-address"
                  />

                  <TextFieldInput
                    md={3}
                    name="mailingAddressCity"
                    disabled={isStreetChecked}
                    error={errors.mailingAddressCity}
                    touched={touched.mailingAddressCity}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="City"
                    onChange={(e) => {
                      formikARef.current.setFieldValue('mailingAddressCity', e.target.value)
                    }}
                    id="mail-city"
                  />

                  <TextFieldInput
                    md={3}
                    name="mailingAddressState"
                    error={errors.mailingAddressState}
                    disabled={isStreetChecked}
                    touched={touched.mailingAddressState}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="State"
                    onChange={(e) => {
                      formikARef.current.setFieldValue('mailingAddressState', e.target.value)
                    }}
                    id="mail-state"
                  />

                  <TextFieldInput
                    md={3}
                    name="mailingAddressZip"
                    error={errors.mailingAddressZip}
                    disabled={isStreetChecked}
                    touched={touched.mailingAddressZip}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="Zip"
                    onChange={(e) => {
                      formikARef.current.setFieldValue('mailingAddressZip', e.target.value)
                    }}
                    id="main-code"
                  />

                  <Grid item xs={12} md={12}>
                    <span
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                      }}
                    >
                      Ownership Details:
                    </span>
                  </Grid>
                  <TextFieldInput
                    md={3}
                    name="owner1FirstName"
                    error={errors.owner1FirstName}
                    touched={touched.owner1FirstName}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="Owner 1 FirstName"
                    onChange={(e) => {
                      const { value } = e.target
                      formikARef.current.setFieldValue('owner1FirstName', value)
                      updateGuarantorAndPre(value, formikARef.current.values.owner1LastName)
                    }}
                  />

                  <TextFieldInput
                    md={3}
                    name="owner1LastName"
                    error={errors.owner1LastName}
                    touched={touched.owner1LastName}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="Owner 1 LastName"
                    onChange={(e) => {
                      const { value } = e.target
                      formikARef.current.setFieldValue('owner1LastName', value)
                      updateGuarantorAndPre(formikARef.current.values.owner1FirstName, value)
                    }}
                  />

                  <TextFieldInput
                    md={3}
                    name="owner1Title"
                    error={errors.owner1Title}
                    touched={touched.owner1Title}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="Owner 1 Title"
                    onChange={(e) => {
                      formikARef.current.setFieldValue('owner1Title', e.target.value)
                    }}
                  />

                  <TextFieldInput
                    md={3}
                    name="owner1Phone"
                    error={errors.owner1Phone}
                    touched={touched.owner1Phone}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="Owner 1 Phone"
                    onChange={(e) => {
                      const formattedValue = formatPhoneNumber(e.target.value)
                      formikARef.current.setFieldValue('owner1Phone', formattedValue)
                    }}
                  />
                  <Grid item xs={12}>
                    <TextFieldInput
                      md={3}
                      name="owner1Email"
                      error={errors.owner1Email}
                      touched={touched.owner1Email}
                      spanStyle={{
                        float: 'left',
                        fontWeight: 'bold',
                      }}
                      heading="Owner 1 Email"
                      type={'email'}
                      onChange={(e) => {
                        formikARef.current.setFieldValue('owner1Email', e.target.value)
                      }}
                    />
                  </Grid>

                  <TextFieldInput
                    md={3}
                    name="owner2FirstName"
                    error={errors.owner2FirstName}
                    touched={touched.owner2FirstName}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="Owner 2 FirstName"
                    onChange={(e) => {
                      formikARef.current.setFieldValue('owner2FirstName', e.target.value)
                    }}
                  />

                  <TextFieldInput
                    md={3}
                    name="owner2LastName"
                    error={errors.owner2LastName}
                    touched={touched.owner2LastName}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="Owner 2 LastName"
                    onChange={(e) => {
                      formikARef.current.setFieldValue('owner2LastName', e.target.value)
                    }}
                  />

                  <TextFieldInput
                    md={3}
                    name="owner2Title"
                    error={errors.owner2Title}
                    touched={touched.owner2Title}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="Owner 2 Title"
                    onChange={(e) => {
                      formikARef.current.setFieldValue('owner2Title', e.target.value)
                    }}
                  />

                  <TextFieldInput
                    md={3}
                    name="owner2Phone"
                    error={errors.owner2Phone}
                    touched={touched.owner2Phone}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="Owner 2 Phone"
                    onChange={(e) => {
                      const formattedValue = formatPhoneNumber(e.target.value)
                      formikARef.current.setFieldValue('owner2Phone', formattedValue)
                    }}
                  />

                  <TextFieldInput
                    md={3}
                    name="owner2Email"
                    error={errors.owner2Email}
                    touched={touched.owner2Email}
                    type={'email'}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="Owner 2 Email"
                    onChange={(e) => {
                      formikARef.current.setFieldValue('owner2Email', e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <span
                    style={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                  >
                    Seller's Deposit Account
                  </span>
                </Grid>

                <Grid item xs={12} sx={{ background: '' }} container spacing={3}>
                  <TextFieldInput
                    name="nameOfDepositoryInstitution"
                    error={errors.nameOfDepositoryInstitution}
                    touched={touched.nameOfDepositoryInstitution}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    heading="Name of Depository Institution"
                    onChange={(e) => {
                      formikARef.current.setFieldValue('nameOfDepositoryInstitution', e.target.value)
                      formikARef.current.setFieldValue('nameOfBank', e.target.value)
                    }}
                  />

                  <TextFieldInput
                    name="sellerABATransit"
                    error={errors.sellerABATransit}
                    touched={touched.sellerABATransit}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    type="number"
                    heading="ABA Transit/Routing #"
                    onChange={(e) => {
                      formikARef.current.setFieldValue('sellerABATransit', e.target.value)
                      formikARef.current.setFieldValue('abaTransit', e.target.value)
                    }}
                  />

                  <TextFieldInput
                    name="checkingAccount"
                    error={errors.checkingAccount}
                    touched={touched.checkingAccount}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                    }}
                    type="number"
                    heading="Checking Account #"
                    onChange={(e) => {
                      formikARef.current.setFieldValue('checkingAccount', e.target.value)
                      formikARef.current.setFieldValue('accountNo', e.target.value)
                    }}
                  />

                  <Grid item xs={12}>
                    <span
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                      }}
                    >
                      Seller’s Deposit Account
                    </span>
                  </Grid>

                  <TextCurrencyField
                    md={3}
                    name="purchasePricePaidToSeller"
                    error={errors.purchasePricePaidToSeller}
                    touched={touched.purchasePricePaidToSeller}
                    heading="Purchase Price Paid to Seller"
                    allowFloat={true}
                    onChange={(e) => {
                      // e.preventDefault()
                      const numericValue = e.target.value.replace(/[^0-9.]/g, '')
                      if (/^\d*\.?\d*$/.test(numericValue) || numericValue === '') {
                        console.log("numericValue-------------Purchase Price Paid---------------", numericValue)
                        // Set field value directly without relying on Formik's onChange
                        formikARef.current.setFieldValue('purchasePricePaidToSeller', numericValue)
                        let resultNY =
                          (+numericValue || 0) -
                          (+formikARef?.current?.values?.purchasePricePaidToSeller || 0) +
                          (+formikARef?.current?.values?.wireFee) +
                          (+formikARef?.current?.values?.processingFee) +
                          ((+formikARef?.current?.values?.administrativeFee) * (+formikARef?.current?.values?.NyTM || 0))
                        let resultCa =
                          (+numericValue || 0) -
                          (+formikARef?.current?.values?.purchasePricePaidToSeller || 0) +
                          (+formikARef?.current?.values?.wireFee) +
                          (+formikARef?.current?.values?.processingFee) +
                          ((+formikARef?.current?.values?.administrativeFee) * (+formikARef?.current?.values?.CaTM || 0))
                        let resultGa =
                          (+formikARef?.current?.values?.purchasedAmmountofFutureReceipts || 0) -
                          (+formikARef?.current?.values?.purchasePricePaidToSeller || 0) +
                          (+formikARef?.current?.values?.administrativeFee || 0) *
                          (+formikARef?.current?.values?.GaTM || 0)
                        let resultKs =
                          (+formikARef?.current?.values?.purchasedAmmountofFutureReceipts || 0) -
                          (+formikARef?.current?.values?.purchasePricePaidToSeller || 0) +
                          (+formikARef?.current?.values?.processingFee || 0) +
                          (+formikARef?.current?.values?.wireFee || 0) + (+formikARef?.current?.values?.originationFee || 0)
                        formikARef.current.setFieldValue('NyFC', resultNY)
                        formikARef.current.setFieldValue('NyPrepayment', +resultNY)
                        formikARef.current.setFieldValue('GaTDC', resultGa)
                        formikARef.current.setFieldValue('KsTDC', resultKs)

                        formikARef.current.setFieldValue('CaFC', resultCa)
                        formikARef.current.setFieldValue('CaPrepayment', resultCa)

                        formikARef.current.setFieldValue('NyFA', numericValue)

                        // caDisclosure && formikARef.current.setFieldValue('CaFA', numericValue)
                      } else {
                        formikARef.current.setFieldError('purchasePricePaidToSeller', 'Please enter a valid number')
                      }
                    }}
                  />

                  <TextCurrencyField
                    md={3}
                    name="initialPeriodicAmount"
                    error={errors.initialPeriodicAmount}
                    touched={touched.initialPeriodicAmount}
                    heading="Initial Periodic Amount"
                    allowFloat={true}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/[^0-9.]/g, '')

                      if (/^\d*\.?\d*$/.test(numericValue) || numericValue === '') {
                        console.log("numericValue---------Initial Periodic Amount-----------", numericValue)
                        formikARef.current.setFieldValue('initialPeriodicAmount', numericValue)
                        formikARef.current.setFieldValue(
                          'NyEMC',
                          numericValue * (periodicFrequencyValue[formikARef?.current?.values?.periodicFr] || 0)
                        )
                        formikARef.current.setFieldValue('NyEP', numericValue)
                        // ca
                        formikARef.current.setFieldValue(
                          'CaEMC',
                          numericValue * (periodicFrequencyValue[formikARef?.current?.values?.periodicFr] || 0)
                        )
                        formikARef.current.setFieldValue('CaEP', numericValue)
                      } else {
                        formikARef.current.setFieldError('initialPeriodicAmount', 'Please enter a valid number')
                      }
                    }}
                  />

                  <TextCurrencyField
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                    }}
                    allowFloat={true}
                    md={3}
                    name="purchasedAmmountofFutureReceipts"
                    error={errors.purchasedAmmountofFutureReceipts}
                    touched={touched.purchasedAmmountofFutureReceipts}
                    heading="Purchased Amount of Future Receipts"
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/[^0-9.]/g, '')

                      if (/^\d*\.?\d*$/.test(numericValue) || numericValue === '') {
                        formikARef.current.setFieldValue('purchasedAmmountofFutureReceipts', numericValue)

                        let resultNY =
                          (+numericValue || 0) -
                          (+formikARef?.current?.values?.purchasePricePaidToSeller || 0) +
                          (+formikARef?.current?.values?.wireFee) +
                          (+formikARef?.current?.values?.processingFee) +
                          ((+formikARef?.current?.values?.administrativeFee) * (+formikARef?.current?.values?.NyTM || 0))

                        let resultCa =
                          (+numericValue || 0) -
                          (+formikARef?.current?.values?.purchasePricePaidToSeller || 0) +
                          (+formikARef?.current?.values?.wireFee) +
                          (+formikARef?.current?.values?.processingFee) +
                          ((+formikARef?.current?.values?.administrativeFee) * (+formikARef?.current?.values?.CaTM || 0))
                        let resultGa =
                          (+numericValue || 0) -
                          (+formikARef?.current?.values?.purchasePricePaidToSeller || 0) +
                          (+formikARef?.current?.values?.administrativeFee * (+formikARef?.current?.values?.GaTM || 0))
                        let resultGaTAP =
                          (+numericValue || 0) +
                          (+formikARef?.current?.values?.administrativeFee * (+formikARef?.current?.values?.GaTM || 0))
                        let resultKs =
                          (+numericValue || 0) -
                          (+formikARef?.current?.values?.purchasePricePaidToSeller || 0) +
                          (+formikARef?.current?.values?.originationFee + (+formikARef?.current?.values?.wireFee || 0) + (+formikARef?.current?.values?.processingFee || 0))
                        let resultKsTAP =
                          (+numericValue || 0) +
                          (+formikARef?.current?.values?.administrativeFee * (+formikARef?.current?.values?.KsTM || 0))

                        formikARef.current.setFieldValue('NyFC', resultNY)
                        formikARef.current.setFieldValue('NyPrepayment', resultNY)
                        formikARef.current.setFieldValue('GaTDC', resultGa)
                        formikARef.current.setFieldValue('KsTDC', resultKs)
                        formikARef.current.setFieldValue('GaTAP', resultGaTAP)
                        formikARef.current.setFieldValue('KsTAP', resultKsTAP)
                        formikARef.current.setFieldValue('CaFC', resultCa)
                        formikARef.current.setFieldValue('CaPrepayment', resultCa)
                        formikARef.current.setFieldValue('NyTPA', numericValue)
                        formikARef.current.setFieldValue('CaTPA', numericValue)
                      } else {
                        formikARef.current.setFieldError(
                          'purchasedAmmountofFutureReceipts',
                          'Please enter a valid number'
                        )
                      }
                    }}
                  />

                  <TextPercentageField
                    md={3}
                    name={'specifiedPercentage'}
                    error={errors.specifiedPercentage}
                    touched={touched.specifiedPercentage}
                    heading={'Specified Percentage'}
                    onChange={(e) => {
                      formikARef.current.setFieldValue('specifiedPercentage', e.target.value)
                      formikARef.current.setFieldValue('NyRemit', e.target.value)
                    }}
                  />

                  <Grid item xs={12}>
                    <span
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        fontSize: '130%',
                      }}
                    >
                      Itemization Of Net Amount Funded To Seller
                    </span>
                  </Grid>

                  <TextCurrencyField
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                      fontSize: '120%',
                    }}
                    disabled={true}
                    allowFloat={true}
                    value={
                      values.purchasePricePaidToSeller
                    }
                    name="purchasePrice"
                    heading="Purchase Price"
                  />

                  <TextCurrencyField
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                      fontSize: '120%',
                    }}
                    allowFloat={true}
                    name="priorBalance"
                    error={errors.priorBalance}
                    touched={touched.priorBalance}
                    heading="Prior Balance"
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/[^0-9.]/g, '')

                      if (/^\d*\.?\d*$/.test(numericValue) || numericValue === '') {
                        formikARef.current.setFieldValue('priorBalance', numericValue)
                      } else {
                        formikARef.current.setFieldError('priorBalance', 'Please enter a valid number')
                      }
                    }}
                  />

                  <TextCurrencyField
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                      fontSize: '120%',
                    }}
                    allowFloat={true}
                    name="wireFee"
                    error={errors.wireFee}
                    touched={touched.wireFee}
                    heading="Wire Fee"
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/[^0-9.]/g, '')
                      if (/^\d*\.?\d*$/.test(numericValue) || numericValue === '') {
                        formikARef.current.setFieldValue('wireFee', numericValue)
                      } else {
                        formikARef.current.setFieldError('wireFee', 'Please enter a valid number')
                      }
                    }}
                  />

                  <TextCurrencyField
                    md={6}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                      fontSize: '120%',
                    }}
                    allowFloat={true}
                    name="processingFee"
                    error={errors.processingFee}
                    touched={touched.processingFee}
                    heading="Processing Fee"
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/[^0-9.]/g, '')

                      if (/^\d*\.?\d*$/.test(numericValue) || numericValue === '') {
                        formikARef.current.setFieldValue('processingFee', numericValue)
                      } else {
                        formikARef.current.setFieldError('processingFee', 'Please enter a valid number')
                      }
                    }}
                  />

                  <TextCurrencyField
                    md={6}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                      fontSize: '120%',
                    }}
                    allowFloat={true}
                    name="administrativeFee"
                    error={errors.administrativeFee}
                    touched={touched.administrativeFee}
                    heading="Administrative Fee"
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/[^0-9.]/g, '')

                      if (/^\d*\.?\d*$/.test(numericValue) || numericValue === '') {
                        formikARef.current.setFieldValue('administrativeFee', numericValue)

                        let resultNY =
                          (+formikARef?.current?.values?.purchasedAmmountofFutureReceipts || 0) -
                          (+formikARef?.current?.values?.purchasePricePaidToSeller || 0) +
                          (+formikARef?.current?.values?.wireFee || 0) +
                          (+formikARef?.current?.values?.processingFee || 0) +
                          ((+numericValue || 0) * (+formikARef?.current?.values?.NyTM || 0))
                        let resultCa =
                          (+formikARef?.current?.values?.purchasedAmmountofFutureReceipts || 0) -
                          (+formikARef?.current?.values?.purchasePricePaidToSeller || 0) +
                          (+formikARef?.current?.values?.wireFee) +
                          (+formikARef?.current?.values?.processingFee) +
                          ((+numericValue || 0) * (+formikARef?.current?.values?.CaTM || 0))
                        let resultGa =
                          (+formikARef?.current?.values?.purchasedAmmountofFutureReceipts || 0) -
                          (+formikARef?.current?.values?.purchasePricePaidToSeller || 0) +
                          (+numericValue * (+formikARef?.current?.values?.GaTM || 0))
                        let resultTAPGa =
                          (+formikARef?.current?.values?.purchasedAmmountofFutureReceipts || 0) +
                          (+numericValue * (+formikARef?.current?.values?.GaTM || 0))
                        let resultTAPKs =
                          (+formikARef?.current?.values?.purchasedAmmountofFutureReceipts || 0) +
                          (+numericValue * (+formikARef?.current?.values?.KsTM || 0))

                        formikARef.current.setFieldValue('NyFC', resultNY)
                        formikARef.current.setFieldValue('NyPrepayment', resultNY)
                        formikARef.current.setFieldValue('GaTDC', resultGa)
                        formikARef.current.setFieldValue('GaTAP', resultTAPGa)
                        formikARef.current.setFieldValue('KsTAP', resultTAPKs)
                        //ca

                        formikARef.current.setFieldValue('CaFC', resultCa)
                        formikARef.current.setFieldValue('CaPrepayment', resultCa)
                      } else {
                        formikARef.current.setFieldError('administrativeFee', 'Please enter a valid number')
                      }
                    }}
                  />

                  <TextCurrencyField
                    md={6}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                      fontSize: '120%',
                    }}
                    allowFloat={true}
                    name="originationFee"
                    error={errors.originationFee}
                    touched={touched.originationFee}
                    heading="Origination Fee"
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/[^0-9.]/g, '')

                      if (/^\d*\.?\d*$/.test(numericValue) || numericValue === '') {
                        formikARef.current.setFieldValue('originationFee', numericValue)
                      } else {
                        formikARef.current.setFieldError('originationFee', 'Please enter a valid number')
                      }
                    }}
                  />

                  <TextCurrencyField
                    md={6}
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                      fontSize: '120%',
                    }}
                    allowFloat={true}
                    disabled={true}
                    value={parseFloat(
                      (values?.purchasePricePaidToSeller || 0) -
                      (values?.wireFee || 0) -
                      (values?.priorBalance || 0) -
                      (values?.processingFee || 0) -
                      (values?.originationFee || 0)).toFixed(2)
                    }
                    name="netAmountSeller"
                    heading="Net Amount Funded to Seller"
                  />
                </Grid>
                <Grid item xs={12} sx={{ background: '' }} container spacing={3}>
                  <Grid item xs={12}>
                    <span
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        fontSize: '130%',
                      }}
                    >
                      Agreement of Seller
                    </span>
                  </Grid>

                  <TextFieldInput
                    md={3}
                    name="seller"
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                      fontSize: '120%',
                    }}
                    heading="Seller"
                    disabled={true}
                  />

                  <Grid item xs={12}>
                    <span
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        fontSize: '130%',
                      }}
                    >
                      Transfer Funds To/From:{' '}
                    </span>
                  </Grid>
                  <TextFieldInput
                    name="nameOfBank"
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                      fontSize: '120%',
                    }}
                    heading="Name of Bank"
                    disabled={true}
                  />

                  <TextFieldInput
                    name="abaTransit"
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                      fontSize: '120%',
                    }}
                    heading="ABA Transit/Routing #"
                    disabled={true}
                  />

                  <TextFieldInput
                    name="accountNo"
                    spanStyle={{
                      float: 'left',
                      fontWeight: 'bold',
                      fontSize: '120%',
                    }}
                    heading="Account #"
                    disabled={true}
                  />
                </Grid>
                <SelectAddendumAndDisclosure
                  gAFLUTDisclosure={gAFLUTDisclosure}
                  setGAFLUTDsclosure={setGAFLUTDsclosure}
                  uccAddendumChecked={uccAddendumChecked}
                  setUccAddendumChecked={setUccAddendumChecked}
                  prePaymentAddendumChecked={prePaymentAddendumChecked}
                  setPrePaymentAddendumChecked={setPrePaymentAddendumChecked}
                  setNyDisclosure={setNyDisclosure}
                  nyDisclosure={nyDisclosure}
                  setCaDisclosure={setCaDisclosure}
                  caDisclosure={caDisclosure}
                  KsDsclosure={KsDsclosure}
                  setKsDsclosure={setKsDsclosure}
                  formik={formikARef}
                />
              </Container>
              {uccAddendumChecked && <UCCAddendum />}
              {prePaymentAddendumChecked && (
                <PrePaymentAddendum
                  errors={errors}
                  touched={touched}
                  setSlotsNumber={setSlotsNumber}
                  formik={formikARef}
                  slotsNumber={slotsNumber}
                />
              )}
              {nyDisclosure && <NYDisclosure values={values} errors={errors} touched={touched} formik={formikARef} />}
              {caDisclosure && <CADisclosure values={values} errors={errors} touched={touched} formik={formikARef} />}
              {gAFLUTDisclosure && (
                <GAFLUTDisclosure values={values} errors={errors} touched={touched} formik={formikARef} />
              )}
              {KsDsclosure && (
                <KsDisclosure values={values} errors={errors} touched={touched} formik={formikARef} />
              )}
            </Form>
          )}
        </Formik>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2%',
            marginBottom: '5%',
          }}
        >
          {contractSubmit ? (
            <CircularProgress />
          ) : (
            <>
              <Button
                style={{
                  textAlign: 'center',
                  paddingTop: '1%',
                  paddingBottom: '1%',
                  paddingRight: '5%',
                  paddingLeft: '5%',
                }}
                variant="contained"
                onClick={() => {
                  console.log(
                    `Hanlde Submit ${formikARef?.current?.isValid} ${formikARef?.current?.isValid}  ${formikARef?.current?.isValid}`
                  )
                  console.log(formikARef?.current)
                  if (formikARef.current.isValid === false) {
                    toast.error('Please fill all the fields')
                    toast.error(Object.values(formikARef?.current?.errors)?.[0])
                    return
                  }
                  if (formikARef?.current?.values?.uccAddendumChecked && !formikARef?.current?.isValid) {
                    toast.error('Please correct your UCC Addendum')
                    return
                  }
                  if (prePaymentAddendumChecked && !formikARef?.current?.isValid) {
                    toast.error('Please correct your Pre Payment Addendum')
                    return
                  }
                  formikARef.current.handleSubmit()
                  console.log(formikARef?.current)
                }}
              >
                Save
              </Button>
            </>
          )}
        </div>
      </Container>
    </>
  )
}
