import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button, Container } from "@mui/material";

const NotFound = () => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  };

  const titleStyle = {
    marginBottom: "1rem",
  };

  const linkStyle = {
    marginTop: "1rem",
  };

  return (
    <Container style={containerStyle}>
      <Typography variant="h4" component="h1" style={titleStyle}>
        404 - Page Not Found
      </Typography>
      <Typography variant="body1" component="p">
        The requested page could not be found.
      </Typography>
      <Button
        component={Link}
        to="/"
        variant="contained"
        color="primary"
        style={linkStyle}
      >
        Go to Home
      </Button>
    </Container>
  );
};

export default NotFound;
