import { Grid, InputAdornment, TextField } from '@mui/material'
import { ErrorMessage, Field } from 'formik'

export const TextCurrencyField = ({
  xs = 12,
  md = 4,
  style = {},
  name,
  error,
  touched,
  spanStyle = {
    float: 'left',
    fontWeight: 'bold',
  },
  heading,
  onChange,
  type = 'text',
  disabled = false,
  value = null,
  allowFloat = false
}) => {
  const formatNumber = (value) => {
    return new Intl.NumberFormat('en-US').format(value)
  }

  const formatNumberforFloat = (value) => {
    const test = isNaN(value)
    console.log("test---------------",value)
    if (!test) {
      const formatter = new Intl.NumberFormat('en-US')
      let value1 = value?.toString();
      if (value1 !== 0 && value1?.includes('.')) {
        const [beforeDot, afterDot] = value1.split('.')
        return formatter.format(beforeDot) + '.' + afterDot
      } else {
        return formatter.format(value1)
      }
    }
    return "0"
  }

  return (
    <Grid item xs={xs} md={md} style={style}>
      <span style={spanStyle}>{heading}</span>
      <Field name={name}>
        {({ field, form }) => (
          <TextField
            {...field}
            type={type}
            disabled={disabled}
            variant="outlined"
            fullWidth
            margin="normal"
            value={
              value
                ? allowFloat
                  ? formatNumberforFloat(value)
                  : value
                : formatNumber(field?.value) == 0
                  ? field?.value
                  : allowFloat
                    ? formatNumberforFloat(field?.value)
                    : formatNumber(field?.value)
            }
            onChange={onChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            error={Boolean(error && touched)}
            helperText={<ErrorMessage name={name} />}
          />
        )}
      </Field>
    </Grid>
  )
}
