import { Container, Grid } from '@mui/material'
import { TextCurrencyField } from './TextCurrencyField'
import { TextPercentageField } from './TextPercentageField'
import { TextFieldInput } from './TextField'
import { ThirdPartyPayOff } from './ThirdPartyPayOff'

export const CADisclosure = ({ values, errors, touched, formik }) => {
  return (
    <>
      <h1 style={{ marginLeft: '3%' }}>Ca Disclosure</h1>
      <Container maxWidth="xl">
        <Grid item xs={12} container spacing={3}>
          <TextCurrencyField
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            disabled={true}
            value={values.purchasePricePaidToSeller}
            name="NyFA"
            heading="Funding Amount"
          />

          <TextPercentageField
            name={'CaAPR'}
            error={errors.CaAPR}
            touched={touched.CaAPR}
            heading={'APR'}
            onChange={(e) => {
              formik.current.setFieldValue('CaAPR', e.target.value)
            }}
          />
          <TextFieldInput
            name="CaTM"
            error={errors.CaTM}
            touched={touched.CaTM}
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
            }}
            type={'number'}
            heading="Terms in Month"
            onChange={(e) => {
              const { value } = e.target
              formik.current.setFieldValue('CaTM', value)
              formik.current.setFieldValue('CaET', value ? value * 30 : 0)
              let first =
                (+formik?.current?.values?.purchasedAmmountofFutureReceipts || 0) -
                (+formik?.current?.values?.purchasePricePaidToSeller || 0) + (+formik?.current?.values?.processingFee || 0) + (+formik?.current?.values?.wireFee || 0)
              let second = +formik?.current?.values?.administrativeFee * (+value || 0)
              let third = +first + +second

              formik.current.setFieldValue('CaFC', third.toFixed(2))
              formik.current.setFieldValue('CaPrepayment', third.toFixed(2))
            }}
          />
          <TextFieldInput
            name="CaFC"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Finance charge(s)"
            disabled={true}
          />

          <TextFieldInput
            name="CaTPA"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            type="number"
            heading="Estimated Total Payment Amount"
            disabled={true}
          />

          <TextFieldInput
            name="CaEMC"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Estimated Monthly Cost"
            disabled={true}
          />

          <TextCurrencyField
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            name="CaEP"
            value={values.initialPeriodicAmount}
            disabled={true}
            heading="Estimated Payment(s)"
          />

          <TextPercentageField name={'NyRemit'} disabled={true} heading={'Remit'} />

          <TextFieldInput
            name="Cafruquency"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Frequency"
            disabled={true}
          />

          <TextFieldInput
            name="CaET"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Estimated Term"
            disabled={true}
          />

          <TextFieldInput
            name="CaPrepayment"
            spanStyle={{
              float: 'left',
              fontWeight: 'bold',
              fontSize: '120%',
            }}
            heading="Prepayment"
            disabled={true}
          />

          <TextCurrencyField
            name="CaIB"
            error={errors.NyIB}
            touched={touched.NyIB}
            heading="Internal Balance"
            allowFloat={true}
            onChange={(e) => {
              e.preventDefault()
              const numericValue = e.target.value.replace(/[^0-9.]/g, '')
              if (/^\d*\.?\d*$/.test(numericValue) || numericValue === '') {
                // Set field value directly without relying on Formik's onChange
                formik.current.setFieldValue('CaIB', numericValue)
              } else {
                formik.current.setFieldError('CaIB', 'Please enter a valid number')
              }
            }}
          />

          <TextCurrencyField
            name="CaMonthlyAvgRev"
            error={errors.CaMonthlyAvgRev}
            touched={touched.CaMonthlyAvgRev}
            heading="Monthly Average Revenue"
            allowFloat={true}
            onChange={(e) => {
              e.preventDefault()
              const numericValue = e.target.value.replace(/[^0-9.]/g, '')
              if (/^\d*\.?\d*$/.test(numericValue) || numericValue === '') {
                // Set field value directly without relying on Formik's onChange
                formik.current.setFieldValue('CaMonthlyAvgRev', numericValue)
              } else {
                formik.current.setFieldError('CaMonthlyAvgRev', 'Please enter a valid number')
              }
            }}
          />
        </Grid>
        <ThirdPartyPayOff disclosure="Ca" errors={errors} touched={touched} formik={formik} />
      </Container>
    </>
  )
}
