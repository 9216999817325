import React from "react";
import { MainRoutes } from "./routes/Routes";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <MainRoutes />
  )
 
}

export default App;
