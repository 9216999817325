// PaginationComponent.js
import React from "react";
import { Pagination } from "@mui/material";

const PaginationComponent = ({ totalSize, currentPage, onChangePage }) => {
  const handleChange = (event, page) => {
    onChangePage(page);
  };

  return (
    <Pagination
      count={totalSize}
      page={currentPage}
      onChange={handleChange}
      color="primary"
    />
  );
};

export default PaginationComponent;
