import React from 'react'
import { Navigate } from 'react-router-dom'

export default function ProtectedRoute({children}) {
    const isAuthorized = localStorage.getItem("token");
    if(isAuthorized)
    {
        return children; 
    }
    else{
        return <Navigate to={`/login`} />
    }
    
}
