import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from './Header'
import { Grid } from '@mui/material'
import CustomSidebar from './Sidebar'
const Layout = ({ children }) => {
  console.log('children', children)
  const location = useLocation()

  if (
    location.pathname == '/login' ||
    location.pathname == '/forgot-password' ||
    location.pathname.includes('/reset-password/') ||
    location.pathname == '/qtm-contract/:id' ||
    location.pathname == '/dynamic-contract/:id' ||
    location.pathname == '/manager'
  ) {
    return (
      <div>
        <main>{children}</main>
      </div>
    )
  } else {
    return (
      <main>
        <Grid container>
          <Grid xs={12}>
            <Header />
          </Grid>
          <Grid xs={2}>
            <CustomSidebar />
          </Grid>
          <Grid xs={10}> {children} </Grid>
        </Grid>
      </main>
    )
  }
}

export default Layout
