import axioss from 'axios'

// export const API_URL = "http://localhost:4000/api/v1/"; //local URl
// export const API_URL = "https://test.qtmbridge.com/api/v1/"; //test URL
export const API_URL = "https://qtmbridge.com/api/v1/"; //live URL

const axios = axioss.create({
  baseURL: API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'ngrok-skip-browser-warning': true,
  },
})

axios.interceptors.request.use(async function (config) {
  const token = localStorage.getItem('token')

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Handle request errors here
    // You can also handle 401 Unauthorized errors here and redirect to the login page
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token')
      localStorage.removeItem('role')
      window.location = '/'
    }
    return Promise.reject(error)
  }
)

export default axios
